.right-modal {
  position: fixed;
  top: 0;
  width: 600px;
  height: 100%;
  background-color: white;
  box-shadow: -4px 0 15px rgba(0,0,0,0.1);
  transition: all 0.5s ease-in; /* Smooth transition */
  z-index: 1000;
  padding: 0;
  overflow-y: auto;
}

.modal-header {
  border-bottom: 1px solid #e0e0e0;
  padding: 24px;
  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 500;
      color: #333;
    }
    .modal-close-btn {
      background: none;
      border: none;
      font-size: 0;
      line-height: 1;
      color: #666;
      cursor: pointer;
      transition: all 0.3s ease;
      padding: 6px;
      &:hover, &:focus {
        color: #000;
        background: #f3f4f6;
      }
      &:focus {
        outline: 1px solid #000;
      }
    }
  }
}
.modal-body {
  padding: 24px;
  height: calc(100% - 155px);
  overflow: auto;
  .client-main-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    h3 {
      margin-bottom: 4px;
      color: #1a1a1a;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.333;
    
    }
    .client-id {
      color: #64748b;
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
    }
    .c-btn {
      margin: 0;
      padding: 7px 12px;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  } 
  .referral-summary {
    margin-bottom: 16px;
  }
  
  .referral-box {
    background-color: #f8fafc;
    padding: 16px;
    border: 1px solid #e2e8f0;
    .referral-title {
      color:#64748b;
      font-size: 14px;
      line-height: 1.43;
    }
    .referral-count {
      font-size: 36px;
      line-height: 1;
      font-weight: bold;
      color: #12a19a;
      margin-top: 4px;
    }
  }
  .referred-clients-table {
    table {
      width: 100%;
      border-collapse: collapse;
      th, td {
        &:first-of-type {
          padding-inline-start: 0;
        }
        &:last-of-type {
          padding-inline-end: 0;
        }
      }
      th {
        border-bottom: 1px solid #e2e8f0;
        padding: 10px;
        text-align: left;
        color: #64748b;
        font-weight: 500;
      }
      td {
        padding: 10px;
        border-bottom: 1px solid #e2e8f0;
        color: #020817;
        &.client-name-cell {
          a {
            color: #12a19a;
            transition: color 0.3s ease;
          }
        } 
      }
    }
  }
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
}

.form-group {
  margin-bottom: 16px;

  label {
    display: block;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.218;
    color: #020817;
  }

  input[type="text"],
  input[type="number"],
  input[type="datetime-local"],
  select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e2e8f0;
    color: #020817;
    font-size: 14px;
    line-height: 1.425;
    min-height: 40px;
  }
  input[type="datetime-local"] {
    max-width: 384px;
    width: 100%;
  }
  .coupon-description {
    font-size: 14px;
    line-height: 1.425;
    color: #64748b;
    margin: 8px 0 0 0;
  }
  .min-job-value {
    margin: 16px 0 0 25px;
    max-width: 250px;
  }
}

.modal-checkbox-block {
  border-top: 1px solid #e0e0e0;
  padding-top: 24px;
  margin-top: 32px;
  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.555;
    margin-bottom: 16px;
  }
  .custom-checkbox {
    &:not(:last-of-type)  {
      margin-bottom: 12px;
    }
    label {
      font-size: 14px;
      color: #020817;
      font-weight: 400;
      &::before {
        margin-inline-end: 10px;
      }
    }
  }
  .specific-jobs-block {
    max-width: 300px;
    margin: 20px 0 0 24px;
    padding: 16px;
    border: 1px solid #e0e0e0;
  }
  .react-datepicker-wrapper {
    width: 250px;
    .react-datepicker__input-container {
      &::before {
        content: "\e823";
        font-family: "mav-icons";
        font-weight: normal;
        display: inline-block;
        line-height: 1;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: #9b9b9b;
      }
      input {
        cursor: pointer;
        &:hover {
          background-color: #f1f5f9;
        }
        &::placeholder {
          font-weight: 500;
          color: #0f172a;
        }
      }
    }
  }
  .react-datepicker-popper {
    left: 30px !important;
  }
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-inline-start: 24px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkbox {
      &::after {
        display: block;
        }
      }
  }
  .checkbox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #000000;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      width: 10px;
      height: 10px;
      background-color:#4aa49d;  ;
      transform: translate(-50%, -50%);
    }
  }
}
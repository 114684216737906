.ban-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ban-modal-content {
  background-color: #edefef;
  width: 600px;
  padding: 32px;
  .ban-modal-header {
    margin-bottom: 32px;
    text-align: center;
    h2 {
      font-family: "Source Sans Pro", sans-serif;
      margin: 0 0 12px 0;
      font-size: 24px;
      color: #000000;
      line-height: 1.25;
      font-weight: 600; 
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.25;
      max-width: 472px;
      margin: 0 auto;
    }
  }
  .ban-modal-body {
    .job-card {
      padding: 20px;
      background: #f9f9f9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
      .job-details {
        font-size: 16px;
        color: #000000;
        h4 {
          font-family: "Source Sans Pro", sans-serif;
          font-size: 16px;
          line-height: 1;
          font-weight: 600;
          color: #000000;
          margin: 0 0 10px;
          svg {
            margin: 0 8px;
          }
        }
      }

      .view-btn-ban {
        background: #00a79d;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 69px;
        height: 41px;
        padding: 11px 20px 12px 20px;
        color: #fff;
      }
    }
  }
  .ban-modal-footer {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 32px;
    .btn {
      min-width: 120px !important;
    }
    .btn-danger {
      background-color: #FB2929 !important;
    }
  }
}
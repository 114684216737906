/*------------------------------------*\ 
    welcome-layout     
\*------------------------------------*/

// Component List

// Clients page
// [ ] subheader-info-panel (with search)
// [ ] checkbox-radio-block (maybe checkbox element to be a stand alone comp if used elsewhere)
// [ ] pagination
// [ ] list-entry

// Edit Client
// [ ] avatar
// [ ] filters (maybe to checkbox on left?)

// Messages
// —

// Edit message
// [ ] text-box
// [ ] messages could just be utilities?
// [ ]

/*------------------------------------*\ 
    navigation   
\*------------------------------------*/
.c-navigation {
  &__item {
    display: block;
    cursor: pointer;
    text-align: center;
    padding: 15px 12px;
    color: $color-2;
    // color: #12a19a; // PS color
    line-height: 1;
    text-decoration: none;
    @include font-semi-bold;

    img {
      margin: 0 auto 13px;
    }

    &.is-active,
    &:hover {
      background: black;
      border-radius: 20% 0 0 20%;
    }
  }
}
.c-navigation__item.active {
  background-color: black;
  color: #f1f1f1;
  border-radius: 20% 0 0 20%;
}

/*------------------------------------*\ 
    base layout   
\*------------------------------------*/
.l-base-layout {
  width: 90%;
  margin: 0 auto;
  display: flex;
  min-height: 100%;

  &__menu {
    width: 112px;
    height: 100%;
    // padding: 12px;
  }

  &__content {
    width: 90%;
    background: white;
    // height: 100%;
    padding: 15px;
    padding-top: 30px;
    min-height: 100vh;
  }
}

/*------------------------------------*\ 
    hr
\*------------------------------------*/
.c-hr {
  height: 1px;
  background: #edeef0;
  margin-bottom: $sp-s;
}

/*------------------------------------*\ 
    info block
\*------------------------------------*/
.c-info-block {
  background: $color-grey-xlight;
  min-height: 206px;
  line-height: 1.5;
  padding: 12px;
  cursor: pointer;
  @include font-semi-bold;

  &__large {
    @include font-regular;
    font-size: 96px;
    line-height: 0.9;
    margin-bottom: 5px;
  }

  &--green {
    color: $color-1;
  }

  &--teal {
    color: $color-2;
  }
}

/*------------------------------------*\ 
    report info block
\*------------------------------------*/
.rpt-cards-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px 0px;
}

.rpt-card {
  background: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2sease-in-out;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: translateY(-5px);
  }

  img {
    width: 50px;
    height: 50px;
    margin: 0 auto 10px;
  }

  p {
    font-size: 16px;
    color: #333;
    margin: 0;
    font-weight: 600;
  }
}

/*------------------------------------*\ 
    subheader info panel
\*------------------------------------*/
.c-subheader-info-panel {
  background: $color-1;
  color: white;
  padding: 2px 1px 2px 8px;
  @include font-semi-bold;
  display: flex;
  // flex-wrap: column;
  align-items: flex-start;

  &__primary {
    padding: 5px 15px 5px 0px;
  }

  &__search {
    margin-left: auto;
    flex-shrink: 0;
    .search-input-block {
      position: relative;
      .input-close-icon {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        color: #000000;
        cursor: pointer;
      }
    }
    .c-btn-outline,
    .c-subheader-info-panel__input {
      height: 38px;
      display: inline-block;
      margin: 0;

    }

    .c-btn-outline {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__input {
    border: none;
    color: $base-font-color;
    background: white;
    padding: 5px;
    height: 38px;
    padding-inline-end: 28px;
  }
}

/*------------------------------------*\ 
	checkbox radio block      
\*------------------------------------*/
.c-checkbox-radio-block {
  // height:40px;
  border-bottom: 1px solid white;
  background: $color-grey-xlight;
  @include font-semi-bold;
  line-height: 1;
  position: relative;

  input {
    position: absolute;
    left: -9999px;

    &:checked + label {
      &:after {
        display: block;
      }
    }
  }

  label {
    display: block;
    padding: 12px 38px 13px 10px;
    cursor: pointer;

    &:after,
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      background: white;
    }

    &:after {
      display: none;
      background: $color-2;
      width: 12px;
      height: 12px;
      right: 14px;
    }
  }
}

.filter-bar {
  display: flex;

  .c-checkbox-radio-block {
    flex-grow: 1;
    margin-right: 1px;
  }
}

/*------------------------------------*\ 
	pagination
\*------------------------------------*/
.c-pagination {
  @include font-semi-bold;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 8px 0;

  &__prev,
  &__next {
    color: $color-2;
    text-transform: uppercase;
  }

  &__page-count {
    flex-grow: 1;
    text-align: center;
  }

  &__quotes {
    flex-grow: 0;
    width: 100px;
  }
}

/*------------------------------------*\ 
    list entry
\*------------------------------------*/

.c-list-entry {
  @include cf;

  // border-bottom: 1px solid $color-grey-xlight;
  // border-top: 1px solid $color-grey-xlight;
  + .c-list-entry {
    border-top: 1px solid $color-grey-xlight;
  }

  margin-top: -1px;
  padding: 3px 0;
  display: flex;
  align-items: flex-start;

  .c-filter__btn {
    font-weight: bold;

    &:after {
      content: "";
      display: block;
      // background:red;
      height: 16px;
      width: 1px;
      @include absolute-vertical-center;
      left: 0;
    }
  }

  .c-list-filter {
    &__button {
      border: 0;
      margin: 0;
      padding: 0;

      // padding: 14px 5px 14px 0;
      &:after {
        content: $icon-angle-right;
        @include mav-icon-font;
        font-size: 12px;
        margin-left: 0.125em;
      }

      &--faded {
        color: $color-grey-dark;
      }

      &--icon-up:after {
        content: $icon-angle-up;
      }

      &--icon-down:after {
        content: $icon-angle-down;
      }
    }
  }

  &__header {
    //border-bottom: 1px solid $color-grey-xlight;
    background-color: $color-grey-xxxlight;
  }

  &__primary {
    padding: 5px 15px 3px 4px;
    flex-grow: 1;
  }

  &__name,
  &__referralClientName,
  &__clientName,
  &__bookedPercentage,
  &__discountCode,
  &__discountStatus,
  &__uses,
  &__startDate,
  &__actions,
  &__id,
  &__mavname,
  &__clientname,
  &__jobdate, 
  &__tel,
  &__email,
  &__btn,
  &__comment,
  &__rating,
  &__location,
  &__mavlocation,
  &__created,
  &__mavquotes,
  &__mavjobs,
  &__clientjobs,
  &__clientcompleted,
  &__reviews,
  &__refunds,
  &__quotes,
  &__amount,
  &__status,
  &__time,
  &__reason,
  &__view {
    padding: 5px 15px 3px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__clientjobs {
    width: 7.5%;
  }
  &__quotes {
    flex-grow: 1;
    width: 7.5%;
  }
  &__discountStatus,
  &__uses {
    width: 9%;
  }
  &__mavquotes, 
  &__mavjobs,
  &__reviews {
    width: 10%;
  }
  &__startDate {
    width: 11%;
  }
  &__rating {
    width: 13%;
  }
  &__id,
  &__mavname,
  &__jobdate, 
  &__tel,
  &__mavlocation {
    width: 15%;
  }
  &__clientname, 
  &__amount, 
  &__status, 
  &__time, 
  &__reason, 
  &__view {
    width: 17%;
  }
  &__name,
  &__created,
  &__clientName {
    width: 20%;
  }
  &__email {
    width: 23%;
  }
  &__bookedPercentage,
  &__location {
    width: 25%;
  }
  &__comment {
    width: 32%;
  }
  &__discountCode {
    width: 42%;
  }
  &__referralClientName {
    width: 60%;
  }
  .disable-btn {
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
  }
  &__clientName {
    color: #19a69d;
  }
  &__mavlocation {
    cursor: default !important;
  }
  &__actions {
    margin-inline-start: auto;
    white-space: nowrap;
  }
  &__clientcompleted,
  &__refunds {
    flex-grow: 1;
  }
  &__view {
    text-align: end;
  }
  .btn-primary {
    margin-inline-start: auto;
  }
  .c-btn {
    margin-bottom: 0;
    margin-left: auto;
    padding-top: 7px;
    padding-bottom: 7px;
    flex-shrink: 0;
  }
  &--large-text {
    font-size: 32px;
    line-height: 1.2;
  }

  &:last-child {
    // border-bottom: 1px solid $color-grey-xlight;
  }

  &:first-child {
    // border-top: none;
  }
}

/*------------------------------------*\ 
    avatar       
\*------------------------------------*/
.c-avatar {
  color: $color-2;
  text-align: center;
  @include font-semi-bold;
  width: 126px;
  font-size: 16px;
  margin-bottom: $sp-m;

  &__pic-wrap {
    width: 126px;
    height: 126px;
    background: $color-grey-light;
    position: relative;
    margin-bottom: 5px;
    // &:before {
    //   content: "AVATAR";
    //   display: block;
    //   text-align: center;
    //   color: white;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    //   font-size: 18px;
    // }
  }

  &__pic {
    position: relative;

    &[src=""] {
      display: none;
    }
  }
}

/*------------------------------------*\ 
    check box with float... utilities?
\*------------------------------------*/
.c-checkbox-radio {
  // height:40px;
  @include font-semi-bold;
  line-height: 1;
  position: relative;
  width: auto;
  display: inline-block;

  input {
    position: absolute;
    left: -9999px;

    &:checked + label {
      &:after {
        display: block;
      }
    }
  }

  label {
    display: block;
    padding: 12px 10px 13px;
    cursor: pointer;
    padding-right: 44px;

    &:after,
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 13px;
      background: white;
      border: 1px solid black;
    }

    &:after {
      border: none;
      display: none;
      background: $color-2;
      width: 12px;
      height: 12px;
      right: 17px;
    }
  }

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 18px;
    background: $color-grey-xlight;
    // background:red;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  &--no-right-border {
    &:after {
      display: none;
    }
  }
}

.c-checkbox-radio-notice {
  display: inline-block;

  &__alert {
    @include font-semi-bold;
    color: $color-yellow;
  }

  span {
    display: inline-block;
    padding: 0px 10px 0px 12px;
  }

  .c-btn--small {
    margin-top: -8px;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-right: 12px;
  }
}

/*------------------------------------*\ 
    input wide
\*------------------------------------*/
.c-input-wide {
  // display: flex;
  // align-items: center;
  @include cf;
  margin-bottom: 5px;

  &__label,
  &__input {
    display: inline-block;
  }

  &__label {
    float: left;
    width: 150px;
    padding-right: 10px;
    padding-top: 5px;
  }

  &__label-alt {
    float: left;
    width: 250px;
    padding-right: 10px;
    padding-top: 5px;
  }

  &__input {
    @include font-semi-bold;
    width: calc(100% - 150px);
    padding: 7px;
    border: 1px solid $color-grey-xdark;
    float: right;
  }

  &__loader {
    background: url("../../img/app/loader-grey.svg") no-repeat center center /
      contain;
    animation: loader 1s steps(12, end) infinite;
    // position: absolute;
    width: 24px;
    height: 24px;
    color: #000000;
    float: right;
  }

  &__copy {
    @include font-semi-bold;
    width: calc(100% - 150px);
    padding: 7px;
    float: right;

    .c-btn--small {
      margin-top: -8px;
      margin-left: 12px;
      margin-bottom: 0px;
    }
  }

  &__copy-normal {
    @include font-regular;
    width: calc(100% - 150px);
    padding: 7px;
    float: right;

    .c-btn--small {
      margin-top: -8px;
      margin-left: 12px;
      margin-bottom: 0px;
    }
  }

  &__copy-alt {
    @include font-semi-bold;
    width: calc(100% - 250px);
    padding: 7px;
    float: right;

    .c-btn--small {
      margin-top: -8px;
      margin-left: 12px;
      margin-bottom: 0px;
    }
  }

  &__slider {
    float: left;
    width: calc(100% - 150px);
  }

  &__textarea {
    @include font-semi-bold;
    //width: calc(100% - 150px);
    width: 100%;
    padding: 7px;
    border: 1px solid $color-grey-xdark;
    float: right;
  }

  &__no-border {
    border: 0;
  }

  &--checkbox {
    .c-input-wide__checkbox-radio-input {
      position: absolute;
      left: -9999px;

      &:checked + label {
        &:after {
          display: block;
        }
      }
    }

    .c-input-wide__label {
      cursor: pointer;
      position: relative;

      &:after,
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
        background: white;
        border: 1px solid black;
      }

      &:after {
        border: none;
        display: none;
        background: $color-2;
        width: 12px;
        height: 12px;
        right: -16px;
      }
    }
  }

  // &__box {
  // 		width: 20px;
  // 		height: 20px;
  // 		margin: 8px 0 7px;
  // 		background: white;
  // 		border:1px solid black;
  // 		float: left;
  // }
}

/*------------------------------------*\
    message bubble
\*------------------------------------*/

.c-message-bubble {
  $class: c-message-bubble;
  margin-bottom: 12px;
  background: #ecefef;
  width: 90%;
  padding: 8px 10px;
  display: flex;
  flex-wrap: wrap;
  line-height: 1.6;
  border-radius: 10px;
  position: relative;
  //cursor: pointer;

  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: #ecefef;
    // border: 1px solid salmon;
    position: absolute;
    bottom: 10px;
    left: 0;
    transform: translateX(-50%) rotate(45deg);
  }

  &__copy {
    flex-grow: 1;

    span {
      //font-size: 0.8rem;
    }
  }

  &__time {
    margin-left: auto;
    // line-height:1
  }

  &--user {
    background: #cbead9;
    margin-left: 10%;

    &.#{$class}:after {
      background: #cbead9;
      left: auto;
      right: 0;
      transform: translateX(50%) rotate(45deg);
    }
  }

  &--rejected {
    background: $color-grey-xxxlight;
    color: $color-grey-dark;

    &.#{$class}:after {
      background: $color-grey-xxxlight;
    }
  }
}

.c-message-moderation {
  $class: c-message-moderation;
  margin-bottom: 12px;
  //background: #ecefef;
  width: 90%;
  //padding: 8px 10px;
  padding: 2px 10px;
  display: flex;
  flex-wrap: wrap;
  //border-radius: 10px;
  position: relative;
  color: $color-grey-dark;

  &__copy {
    flex-grow: 1;

    span {
      font-size: 0.75rem;
    }

    .c-message-moderation-moderatorcomment {
      font-size: 0.8em;
      line-height: 1;
      color: $color-grey-xdark;
    }
  }

  &__time {
    margin-left: auto;
    // line-height:1
  }

  &--user {
    //background: #cbead9;
    margin-left: 10%;
  }
}

/*------------------------------------*\ 
       
\*------------------------------------*/

.c-message-moderation {
  margin-top: 6px;
  line-height: 1em;
}

.c-audit-note-container {
}

.c-audit-note {
  background: $color-grey-xxlight;
  //margin-right: 12px;
  margin-bottom: 12px;
  padding: 12px 12px;
  border-radius: 10px;
  font-size: 16px;

  &__date {
    @include font-semi-bold;
    font-size: 10px;
  }

  strong {
    @include font-bold;
    color: $base-font-color;
  }

  code {
    @include font-regular;
    background-color: $color-yellow;
  }

  p {
    margin-bottom: 0;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}

.c-rich-text-input {
  font-size: 16px;
  border: 1px solid $color-input-border;
  text-align: left;
  padding: 12px;
  background-color: $white;
  min-height: 8em;

  code {
    @include font-regular;
    background-color: $color-yellow;
  }
}

/*------------------------------------*\
    toggle
\*------------------------------------*/

.c-toggle-switch {
  //box-shadow cannot be animated
  //box-shadow is prefereble over border as it does not affect the layout of its contents
  //box-shadow is animated by transitioning the opacity of a pseudo element with box-shadow

  $class: c-toggle-switch;
  //change variables below to customize size
  $toggle-width: 50px;
  $switch-height: 30px;
  $border-width: 0px;
  $switch-scale: 0.84; //for sizing the circle
  $bg-color: $color-green;
  $left-margin: $toggle-width - $switch-height;
  // if you choose to use border, not box shadow, use below $left-margin var
  // $left-margin: $toggle-width - $switch-height - ($border-width * 2);

  padding: 15px 0;
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;
  margin-top: -1px;
  display: flex;
  align-items: center;

  &__text {
    flex-grow: 1;
    padding-right: 10px;
  }

  .small {
    //NEW
    font-size: 0.75rem;
    @include font-regular;
    display: inline-block;
  }

  &__input {
    display: none;
  }

  &__label {
    flex-shrink: 0;
    cursor: pointer;
    background: white;
    width: $toggle-width;
    height: $switch-height;
    display: block;
    border-radius: 500px;
    position: relative;
    transition: border 0.3s ease-in-out, background 0.3s ease-in-out;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      display: block;
      width: $switch-height;
      height: $switch-height;
      background: white;
      border-radius: 50%;
      transform: scale($switch-scale) translateY(-50%);
      transform-origin: 50% 0;
      transition: transform 0.3s ease;
      transition-property: transform, background, left;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25), 0 3px 3px 0 rgba(0, 0, 0, 0.15);
      z-index: 2;
    }

    // this after element exists purely to animate the box-shadow property
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      // box-shadow: inset 0 0 0 $border-width salmon;
      box-shadow: inset 0 0 1px 0 hsl(0, 0%, 70);
      width: $toggle-width;
      height: $switch-height;
      display: block;
      border-radius: 500px;
      transition: transform 0.3s ease;
      transition-property: opacity;
      z-index: 1;
    }
  }

  &__input:checked + &__label {
    // use below if you wish to use border
    // border: $border-width solid salmon;

    // use below if box-shadow color transition is not needed
    // box-shadow: inset 0 0 0 $border-width salmon;

    //show pseudo element (animating a box-shadow)
    background: $bg-color;

    &:after {
      opacity: 0;
    }

    &:before {
      // translateX has a division to the value to compensate for the switch-scale factor
      transform: scale($switch-scale) translateX(($left-margin) / $switch-scale)
        translateY(-50%);
      background: white;
      left: 0;
    }
  }

  &--no-border-top {
    border-top: 0;
  }

  &--no-border-bottom {
    border-bottom: 0;
  }

  &--no-border {
    border: 0;
  }
}

.c-toggle-switch-small {
  // Box-shadow cannot be animated. Box-shadow is preferable over border as it does not affect the layout of its contents.
  // Box-shadow is animated by transitioning the opacity of a pseudo-element with box-shadow

  $toggle-width: calc(50px * 0.75);
  $switch-height: calc(30px * 0.75);
  $switch-scale: calc(0.84 * 0.75); // for sizing the circle
  $bg-color: $color-green;
  $left-margin: $toggle-width - $switch-height;

  padding: 15px 0;
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;
  margin-top: -1px;
  display: flex;
  align-items: center;

  &__text {
    flex-grow: 1;
    padding-right: 10px;
  }

  .small {
    font-size: 0.75rem;
    @include font-regular;
    display: inline-block;
  }

  &__input {
    display: none;
  }

  &__label {
    flex-shrink: 0;
    cursor: pointer;
    background: white;
    width: $toggle-width;
    height: $switch-height;
    display: block;
    border-radius: 500px;
    position: relative;
    transition: transform 0.3s ease, background 0.3s ease, left 0.3s ease;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      display: block;
      width: $switch-height;
      height: $switch-height;
      background: white;
      border-radius: 50%;
      transform: scale($switch-scale) translateX(0%) translateY(-50%);
      transform-origin: 50% 0;
      transition: transform 0.3s ease, background 0.3s ease, left 0.3s ease;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25), 0 3px 3px 0 rgba(0, 0, 0, 0.15);
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      box-shadow: inset 0 0 1px 0 hsl(0, 0%, 70);
      width: $toggle-width;
      height: $switch-height;
      display: block;
      border-radius: 500px;
      transition: transform 0.3s ease;
      transition-property: opacity;
      z-index: 1;
    }
  }

  &__input:checked + &__label {
    background: $bg-color;

    &:after {
      opacity: 0;
    }

    &:before {
      transform: scale($switch-scale) translateX(100%) translateY(-50%);
    }
  }

  &--no-border-top {
    border-top: 0;
  }

  &--no-border-bottom {
    border-bottom: 0;
  }

  &--no-border {
    border: 0;
  }
}

.dev-mode-notice {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  background-color: $white;
  padding: 4px 8px;
  border-radius: 0 0 10px 10px;
  @include font-semi-bold;
  @include font-size(12px, 1.3);
  color: $color-red;
  z-index: $z-dev;
}

.dev-mode-notice-admin {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  background-color: $color-red;
  padding: 4px 8px;
  border-radius: 0 0 10px 10px;
  @include font-semi-bold;
  @include font-size(12px, 1.3);
  color: $white;
  z-index: $z-dev;
}

/*doc
---
title: toggle switch
name: toggle-switch
category: Inputs
---

Multiple components shown for demonstration

```html_example
<div class="c-toggle-switch">
	<span class="c-toggle-switch__text">Push notifications</span>						
	<input id="signup" type="checkbox" class="c-toggle-switch__input" />
	<label for="signup" class="c-toggle-switch__label">
		<span class="u-visually-hidden">Push notifications</span>
		</span>
	</label>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-toggle-switch--no-border-top</button>
        <button>c-toggle-switch--no-border-bottom</button>
        <button>c-toggle-switch--no-border</button>
    </div>
</div>

*/

$input-range-font-family: "Helvetica Neue", san-serif !default;
$input-range-primary-color: rgb(43, 182, 115) !default;
$input-range-neutral-color: rgba(0, 0, 0, 0.8) !default;
$input-range-neutral-light-color: #d1d3d4 !default;
$input-range-disabled-color: #cccccc !default;

// input-range-slider
$input-range-slider-background: rgb(65, 65, 65) !default;
$input-range-slider-border: 2px solid #ecefef !default;
$input-range-slider-focus-box-shadow-radius: 5px !default;
$input-range-slider-focus-box-shadow-color: transparentize(
  $input-range-slider-background,
  0.8
) !default;
$input-range-slider-height: 30px !default;
$input-range-slider-width: 30px !default;
//$input-range-slider-transition: transform 0.3s ease-out, box-shadow 0.3s ease-out !default;
$input-range-slider-transition: none !default;
//$input-range-slider-container-transition: left 0.3s ease-out !default;
$input-range-slider-container-transition: none !default;
$input-range-slider-active-transform: scale(1.3) !default;
$input-range-slider-disabled-background: $input-range-disabled-color !default;
$input-range-slider-disabled-border: 1px solid $input-range-disabled-color !default;

// input-range-label
$input-range-label-color: $input-range-neutral-color !default;
$input-range-label-font-size: 0.8rem !default;
$input-range-label-position-bottom: 35px !default;
$input-range-label-value-position-top: 20px !default;

// input-range-track
$input-range-track-background: $input-range-neutral-light-color !default;
$input-range-track-height: 12px !default;
//$input-range-track-transition: left 0.3s ease-out, width 0.3s ease-out !default;
$input-range-track-transition: none !default;
$input-range-track-active-background: $input-range-primary-color !default;
$input-range-track-disabled-background: $input-range-neutral-light-color !default;

.input-range__slider {
  appearance: none;
  background: $input-range-slider-background;
  border: $input-range-slider-border;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: $input-range-slider-height;
  margin-left: $input-range-slider-width / -2;
  margin-top: $input-range-slider-height / -2 + $input-range-track-height / -2;
  outline: none;
  position: absolute;
  top: 50%;
  transition: $input-range-slider-transition;
  width: $input-range-slider-width;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25), 0 3px 3px 0 rgba(0, 0, 0, 0.15);

  &:active {
    transform: $input-range-slider-active-transform;
  }

  &:focus {
    box-shadow: 0 0 0 $input-range-slider-focus-box-shadow-radius
      $input-range-slider-focus-box-shadow-color;
  }

  .input-range--disabled & {
    background: $input-range-slider-disabled-background;
    border: $input-range-slider-disabled-border;
    box-shadow: none;
    transform: none;
  }
}

.input-range__slider-container {
  transition: $input-range-slider-container-transition;
}

.input-range__label {
  color: $input-range-label-color;
  font-family: $input-range-font-family;
  font-size: $input-range-label-font-size;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  top: $input-range-label-position-bottom;
  position: absolute;
}

.input-range__label--min {
  left: 0;
  display: none;
}

.input-range__label--max {
  right: 0;
  display: none;
}

.input-range__label--value {
  position: absolute;
  bottom: $input-range-label-value-position-top;
  font-size: 14px;
  @include font-semi-bold;
}

.input-range__label-container {
  left: -50%;
  position: relative;

  .input-range__label--max & {
    left: 50%;
  }
}

.input-range__track {
  background: $input-range-track-background;
  border-radius: $input-range-track-height;
  cursor: pointer;
  display: block;
  height: $input-range-track-height;
  position: relative;
  transition: $input-range-track-transition;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.5 * $input-range-track-height;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: $input-range-track-active-background;
}

.input-range__track--stripes {
  position: absolute;
  width: 100%;
  margin-top: -0.5 * $input-range-track-height;
  height: $input-range-track-height;
  right: 0;
  top: 50%;

  background-repeat: no-repeat;
  background-size: 2px $input-range-track-height;

  &--step16 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg);
    background-position: calc((100% / 16) * 1) top, calc((100% / 16) * 2) top,
      calc((100% / 16) * 3) top, calc((100% / 16) * 4) top,
      calc((100% / 16) * 5) top, calc((100% / 16) * 6) top,
      calc((100% / 16) * 7) top, calc((100% / 16) * 8) top,
      calc((100% / 16) * 9) top, calc((100% / 16) * 10) top,
      calc((100% / 16) * 11) top, calc((100% / 16) * 12) top,
      calc((100% / 16) * 13) top, calc((100% / 16) * 14) top,
      calc((100% / 16) * 15) top;
  }

  &--step15 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg);
    background-position: calc((100% / 15) * 1) top, calc((100% / 15) * 2) top,
      calc((100% / 15) * 3) top, calc((100% / 15) * 4) top,
      calc((100% / 15) * 5) top, calc((100% / 15) * 6) top,
      calc((100% / 15) * 7) top, calc((100% / 15) * 8) top,
      calc((100% / 15) * 9) top, calc((100% / 15) * 10) top,
      calc((100% / 15) * 11) top, calc((100% / 15) * 12) top,
      calc((100% / 15) * 13) top, calc((100% / 15) * 14) top;
  }

  &--step14 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg);
    background-position: calc((100% / 14) * 1) top, calc((100% / 14) * 2) top,
      calc((100% / 14) * 3) top, calc((100% / 14) * 4) top,
      calc((100% / 14) * 5) top, calc((100% / 14) * 6) top,
      calc((100% / 14) * 7) top, calc((100% / 14) * 8) top,
      calc((100% / 14) * 9) top, calc((100% / 14) * 10) top;
  }

  &--step13 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg);
    background-position: calc((100% / 13) * 1) top, calc((100% / 13) * 2) top,
      calc((100% / 13) * 3) top, calc((100% / 13) * 4) top,
      calc((100% / 13) * 5) top, calc((100% / 13) * 6) top,
      calc((100% / 13) * 7) top, calc((100% / 13) * 8) top,
      calc((100% / 13) * 9) top, calc((100% / 13) * 10) top,
      calc((100% / 13) * 11) top, calc((100% / 13) * 12) top;
  }

  &--step12 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg);
    background-position: calc((100% / 12) * 1) top, calc((100% / 12) * 2) top,
      calc((100% / 12) * 3) top, calc((100% / 12) * 4) top,
      calc((100% / 12) * 5) top, calc((100% / 12) * 6) top,
      calc((100% / 12) * 7) top, calc((100% / 12) * 8) top,
      calc((100% / 12) * 9) top, calc((100% / 12) * 10) top,
      calc((100% / 12) * 11) top;
  }

  &--step11 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg);
    background-position: calc((100% / 11) * 1) top, calc((100% / 11) * 2) top,
      calc((100% / 11) * 3) top, calc((100% / 11) * 4) top,
      calc((100% / 11) * 5) top, calc((100% / 11) * 6) top,
      calc((100% / 11) * 7) top, calc((100% / 11) * 8) top,
      calc((100% / 11) * 9) top, calc((100% / 11) * 10) top;
  }

  &--step10 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg);
    background-position: calc((100% / 10) * 1) top, calc((100% / 10) * 2) top,
      calc((100% / 10) * 3) top, calc((100% / 10) * 4) top,
      calc((100% / 10) * 5) top, calc((100% / 10) * 6) top,
      calc((100% / 10) * 7) top, calc((100% / 10) * 8) top,
      calc((100% / 10) * 9) top;
  }

  &--step9 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg);
    background-position: calc((100% / 9) * 1) top, calc((100% / 9) * 2) top,
      calc((100% / 9) * 3) top, calc((100% / 9) * 4) top,
      calc((100% / 9) * 5) top, calc((100% / 9) * 6) top,
      calc((100% / 9) * 7) top, calc((100% / 9) * 8) top;
  }

  &--step8 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg);
    background-position: calc((100% / 8) * 1) top, calc((100% / 8) * 2) top,
      calc((100% / 8) * 3) top, calc((100% / 8) * 4) top,
      calc((100% / 8) * 5) top, calc((100% / 8) * 6) top,
      calc((100% / 8) * 7) top;
  }

  &--step7 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg);
    background-position: calc((100% / 7) * 1) top, calc((100% / 7) * 2) top,
      calc((100% / 7) * 3) top, calc((100% / 7) * 4) top,
      calc((100% / 7) * 5) top, calc((100% / 7) * 6) top;
  }

  &--step6 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg);
    background-position: calc((100% / 6) * 1) top, calc((100% / 6) * 2) top,
      calc((100% / 6) * 3) top, calc((100% / 6) * 4) top,
      calc((100% / 6) * 5) top;
  }

  &--step5 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg);
    background-position: calc((100% / 5) * 1) top, calc((100% / 5) * 2) top,
      calc((100% / 5) * 3) top, calc((100% / 5) * 4) top;
  }

  &--step4 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg), url(../../img/app/grey-line.svg);
    background-position: calc((100% / 4) * 1) top, calc((100% / 4) * 2) top,
      calc((100% / 4) * 3) top;
  }

  &--step3 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg);
    background-position: calc((100% / 3) * 1) top;
  }

  &--step2 {
    background-image: url(../../img/app/grey-line.svg),
      url(../../img/app/grey-line.svg);
    background-position: calc((100% / 2) * 1) top;
  }

  &--step1 {
  }
}

.input-range {
  height: $input-range-slider-height;
  position: relative;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-top: $sp-m;
  margin-bottom: $sp-m;
}

.c-days-select {
  $class: c-days-select;
  margin-bottom: 0;

  &__wrapper {
    display: flex;
    width: 100%;
  }

  &__day {
    flex-grow: 1;
    background-color: $color-grey-mid-light;
    @include font-semi-bold;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px;
    border-radius: 2.5px;
    padding-top: $sp-xxs;
    padding-bottom: $sp-xxs;
    text-align: center;
    margin-right: $sp-xxxs;

    &--is-selected {
      background-color: $color-green;
      color: $white;
    }
  }

  &__day:last-child {
    margin-right: 0;
  }
}

.c-avatar-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 8px;

  &__icon {
    visibility: hidden;
    position: absolute;
    right: 8px;
    top: 4px;
    color: $color-red-dark;
    font-size: 20px;
  }

  &__avatar {
    margin: 0px 8px 8px 0px;
    width: 138px;
    height: 138px;

    position: relative;

    &--selected {
      .c-avatar-grid__icon {
        visibility: visible;
      }

      img {
        opacity: 0.5;
      }
    }

    img {
      width: 138px;
      height: 138px;
    }
  }
}

/*------------------------------------*\
    rating
\*------------------------------------*/

.c-rating {
  margin-bottom: $base-vertical-spacing;

  $class: c-rating;
  // $base-horz-padding: 12px;
  // $base-vert-padding: 15px;
  // $padding-left: 81px;
  // $icon-padding-right: 33px;

  // &__validation-msg {
  //   display: none;
  //   color: $color-red;
  //   margin-left: 0;
  //   margin-top: 4px;
  //   font-size: 10px;
  //   line-height: 1.8em;
  // }

  // &.is-invalid {
  //   .#{$class}__validation-msg {
  //     display: block;
  //   }
  // }

  &.is-invalid {
    .#{$class}__title {
      color: $color-red;
    }
  }

  @mixin unchecked-styles-large {
    &:before {
      content: $icon-star-empty;
      @include mav-icon-font;
      font-size: 24px;
    }
  }

  @mixin unchecked-styles {
    &:before {
      content: $icon-star-empty;
      @include mav-icon-font;
      font-size: 20px;
    }
  }

  @mixin unchecked-styles-med {
    &:before {
      content: $icon-star-empty;
      @include mav-icon-font;
      font-size: 16px;
    }
  }

  @mixin unchecked-styles-small {
    &:before {
      content: $icon-star-empty;
      @include mav-icon-font;
      font-size: 12px;
    }
  }

  @mixin checked-styles-large {
    &:before {
      content: $icon-star-full;
      @include mav-icon-font;
      font-size: 24px;
    }
  }

  @mixin checked-styles {
    &:before {
      content: $icon-star-full;
      @include mav-icon-font;
      font-size: 20px;
    }
  }

  @mixin checked-styles-med {
    &:before {
      content: $icon-star-full;
      @include mav-icon-font;
      font-size: 16px;
    }
  }

  @mixin checked-styles-small {
    &:before {
      content: $icon-star-full;
      @include mav-icon-font;
      font-size: 12px;
    }
  }

  $class: c-rating;
  color: $color-green;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__title {
    margin-right: 15px;
    @include font-semi-bold;
  }

  &__wrap {
    display: inline-block;
    padding: 0;
    border: 0;
    margin: 0;
  }

  &__wrap-modal {
    display: block;
    margin: auto;
  }

  &__input {
    display: none;

    &:checked {
      ~ .#{$class}__label {
        @include checked-styles;
      }

      ~ .#{$class}__label-read-only-large {
        @include checked-styles-large;
      }

      ~ .#{$class}__label-read-only {
        @include checked-styles;
      }

      ~ .#{$class}__label-read-only-med {
        @include checked-styles-med;
      }

      ~ .#{$class}__label-read-only-small {
        @include checked-styles-small;
      }
    }
  }

  &__label {
    @include unchecked-styles;
    float: right;
    cursor: pointer;
    padding: 5px;

    &:hover,
    &:focus,
    &:active {
      @include checked-styles;

      ~ .#{$class}__label {
        @include checked-styles;
      }
    }
  }

  &__label-read-only-large {
    @include unchecked-styles-large;
    float: right;
    cursor: pointer;
    padding: 7.5px;
  }

  &__label-read-only {
    @include unchecked-styles;
    float: right;
    cursor: pointer;
    padding: 5px;

    &__modal {
      float: none;
    }
  }

  &__label-read-only-med {
    @include unchecked-styles-med;
    float: right;
    cursor: pointer;
    padding: 2.5px;
  }

  &__label-read-only-small {
    @include unchecked-styles-small;
    float: right;
    cursor: pointer;
    padding: 2.5px;
  }

  &__subtext {
    width: 100%;
  }

  &-list {
    margin: 20px;
    background-color: red($color: #000000);
  }

  &--list {
    margin-bottom: 0;

    .c-rating__label-read-only-small {
      padding: 0 2.5px;
      cursor: default;
    }
  }
}

/*------------------------------------*\
border-bottom: 1px solid white;
  background: $color-grey-xlight;
  @include font-semi-bold;
  line-height: 1;
  position: relative;

  input {
    position: absolute;
    left: -9999px;
    &:checked + label {
      &:after {
        display: block;
      }
    }
  }

  label {
    display: block;
    padding: 12px 38px 13px 10px;
    cursor: pointer;
    &:after,
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      background: white;
    }
    &:after {
      display: none;
      background: $color-2;
      width: 12px;
      height: 12px;
      right: 14px;
    }
  }
  
    rating
\*------------------------------------*/
.c-filter-bar {
  display: flex;
  padding: 5px 10px;
  background: $color-grey-xlight;
  @include font-semi-bold;
  font-size: 16px;
  line-height: 1;
  position: relative;

  .item-container {
    .item-label {
      margin-right: 5px;
    }

    .item-input {
      border-radius: 3px;
      border-width: 1px;
    }
  }
}

.c-filter-bar-2 {
  display: flex;
  padding: 5px 10px;
  background: $color-grey-xlight;
  @include font-semi-bold;
  font-size: 16px;
  line-height: 1;

  .item-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .item-label {
      margin-right: 10px;
    }

    .item-input {
      border: 1px solid lightgray;
      height: 28px;
    }

    .item-input:focus {
      border: 1px solid lightgray;
    }

    .search-button {
      padding: 5px;
      color: white;
      background: #2bb673;
      margin-left: 10px;
      border: 1px solid green;
    }
  }
}

.coming-soon-img-section {
  margin-top: 200px;
  display: flex;
  justify-content: center;
}

/*doc
---
title: rating
name: rating
category: Inputs
---

```html_example
<div class="c-rating">
	
	<div class="c-rating__title">Rate the job</div>
	
	<fieldset class="c-rating__wrap">
	    <input class="c-rating__input" type="radio" id="star5" name="rating" value="5" />
	    <label class="c-rating__label" for="star5"><span class="u-visually-hidden">5 stars</span></label>
	    <input class="c-rating__input" type="radio" id="star4" name="rating" value="4" />
	    <label class="c-rating__label" for="star4"><span class="u-visually-hidden">4 stars</span></label>
	    <input class="c-rating__input" type="radio" id="star3" name="rating" value="3" />
	    <label class="c-rating__label" for="star3"><span class="u-visually-hidden">3 stars</span></label>
	    <input class="c-rating__input" type="radio" id="star2" name="rating" value="2" />
	    <label class="c-rating__label" for="star2"><span class="u-visually-hidden">2 stars</span></label>
	    <input class="c-rating__input" type="radio" id="star1" name="rating" value="1" />
	    <label class="c-rating__label" for="star1"><span class="u-visually-hidden">1 star</span></label>
  	</fieldset>
  	<p class="c-rating__subtext">(From 2 reviews)</p>
</div> 
```

*/

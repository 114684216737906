/*------------------------------------*\
    ##Generic
\*------------------------------------*/

/*--------- resets ---------*/
html,
body {
  height: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  background: #fff;
}
// body { overflow-x:hidden;}
button {
  border: none;
  background: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
a {
  cursor: pointer;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  -ms-interpolation-mode: bicubic; /* image scaling IE fix */
  height: auto;
  display: block;
  max-width: 100%;
}
figure {
  margin: 0;
  padding: 0;
}

/*remove color block on touch areas on touch devices*/
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*to remove rounded corners on IOS*/
input[type="texarea"],
input[type="text"],
input[type="email"],
input[type="tel"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

hr,
.break {
  height: 1px;
  width: 100%;
  background: rgb(195, 195, 195);
  margin-bottom: 2em;
}
hr,
.break {
  background: #cccccc;
  height: 1px;
  border: none;
  display: block;
}

/*---------- margin / padding on block level elements - vertical rhythm ---------*/
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
blockquote,
a,
ul,
ol {
  margin: 0;
}
hgroup,
ul,
ol,
dd,
figure,
pre,
table,
fieldset,
hr,
img {
  margin-bottom: $sp-m;
}

/*margin for all type elements*/
// h1, h2, h3, h4, h5, h6, .u-h1, .u-h2, .u-h3, .u-h4, .u-h5, .u-h6, p {margin-bottom: sp(28);}
h1,
h2,
h3,
h4,
h5,
h6,
.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5,
.u-h6,
p {
  margin-bottom: $sp-m;
}
// h1, h2, h3, h4, h5, h6, .u-h1, .u-h2, .u-h3, .u-h4, .u-h5, .u-h6, p {margin-bottom: 1em;}

p {
  + h1,
  + h2,
  + h3,
  + h4,
  + h5,
  + h6,
  + .u-h1,
  + .u-h2,
  + .u-h3,
  + .u-h4,
  + .u-h5,
  + .u-h6 {
    margin-top: $sp-l;
  }
}
// * {
// 	p:last-child, .c-btn:last-child {margin-bottom: 0;}
// }

// h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p {padding-bottom: 2rem;}
// h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6  {padding-top:.5rem ;padding-bottom: 1.5rem;}

/* Range slider */
.range-slider-main {
  height: 30px;
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
}
.horizontal-slider {
  width: 100%;
  height: 10px;
  background: #d1d3d4;
  border-radius: 5px;
  position: relative;
  // position: absolute;
  // width: 100%;
  // margin-top: -6px;
  // height: 12px;
  // right: 0;
  // top: 50%;
  // background-repeat: no-repeat;
  // background-size: 2px 12px;
  // background-image: url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg), url(../img/app/grey-line.svg);
  // background-position: calc((100% / 16)* 1) top, calc((100% / 16)* 2) top, calc((100% / 16)* 3) top, calc((100% / 16)* 4) top, calc((100% / 16)* 5) top, calc((100% / 16)* 6) top, calc((100% / 16)* 7) top, calc((100% / 16)* 8) top, calc((100% / 16)* 9) top, calc((100% / 16)* 10) top, calc((100% / 16)* 11) top, calc((100% / 16)* 12) top, calc((100% / 16)* 13) top, calc((100% / 16)* 14) top, calc((100% / 16)* 15) top;

  .track {
    // background: #00b383;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    &.track-1 {
      background: #00b383;
    }
  }
  .thumb {
    width: 30px;
    height: 30px;
    background: #000000;
    border: 2px solid #ffffff;
    border-radius: 50%;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -11px;
    &:focus-visible {
      outline: none;
    }
    span {
      position: absolute;
      top: -24px;
      left: -13px;
      width: 50px;
      color: #000000;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      &:before {
        content: "";
        display: none;
      }
    }
  }
  // Mav Time Range CSS
  &.mav-range {
    background: #d1d3d4;
    height: 4px;
    .track {
      &.track-0 {
        background: #00b383;
      }
      &.track-1 {
        background: transparent;
      }
    }
    .thumb {
      height: 30px;
      width: 30px;
      top: -13px;
      background: #ffffff;
      border-color: #cccccc;
      &:hover {
        span {
          display: block;
        }
      }
      span {
        position: absolute;
        top: 35px;
        left: -24px;
        background-color: #d1d3d4;
        width: 75px;
        color: #000000;
        text-align: center;
        border-radius: 3px;
        display: none;
        &:before {
          position: absolute;
          display: block;
          content: "";
          border-color: transparent;
          border-style: solid;
          top: -6px;
          left: 30px;
          border-width: 0.4rem 0.4rem 0;
          border-top-color: #d1d3d4;
          rotate: 180deg;
        }
      }
    }
  }
}

/* Time Range slider */
.input-range {
  .input-range__track {
    background: #e0e0e0;
    height: 10px;
    border-radius: 5px;
  }

  .input-range__track--active {
    background: #00b383;
  }

  .input-range__slider {
    width: 24px;
    height: 24px;
    background: #333;
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: grab;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    &::before {
      content: attr(data-label);
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      color: #000;
    }
  }
}

// Common general css
.error-text {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: red;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.pl-0 {
  padding-inline-start: 0 !important;
}
.d-flex {
  display: flex !important;
}
.align-items-center {
  align-items: center !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.me-0 {
  margin-inline-end: 0 !important;
}
.me-5 {
  margin-inline-end: 5px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.text-end {
  text-align: end !important;
}
.u-margin-left-0 {
  margin-left: 0 !important;
}
.text-gray {
  color: $color-dark-grey !important;
}
.text-yellow {
  color: #be8017 !important;
}
.text-success {
  color: $color-green !important;
}
.text-danger {
  color: $color-red-2 !important;
}
.bg-success {
  background-color: $color-green !important;
}
.img_invert {
  filter: brightness(0) invert(1);
}
.color-teal {
  color: $color-teal !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.g-4 {
  gap: 4px !important;
}
.g-12 {
  gap: 12px !important;
}
.dark_gray_color {
  color: #5a5a5a;
}
.strong_orange {
  color: #be8017;
}
.lime_green {
  color: #289d63;
}
.strong_red {
  color: #be1717;
}
.ml-32 {
  margin-left: 32px;
}

.cursor-default {
  cursor: default !important;
}

.c-pagination__select {
  padding: 4px 8px;
  border: 1px solid #ccc;
}

.c-pagination__input {
  height: 32px;
  width: 33px; /* Default width */
  min-width: 33px; /* Ensures it doesn't shrink */
  max-width: max-content; /* Prevents excessive growth */
  text-align: center;
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 16px;
  font-weight: bold;
  transition: width 0.2s ease-in-out; /* Smooth transition */
}

.c-pagination span {
  display: flex;
  align-items: center;
  gap: 8px;
}
.c-pagination .btn-next-prev {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #e2e8f0;
  background-color: rgb(239, 238, 238);
  cursor: pointer;
}
.btn-next-prev:focus, .c-pagination .btn-next-prev:hover{

  background-color: #fff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  position: relative;
  
  z-index: 1001;

}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.confirm-btn {
  background-color: #008060;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #d3d3d3;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
body.modal-open {
  overflow: hidden;
}
.btn {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  display: inline-block !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  min-width: 0 !important;
  border: 1px solid transparent !important;
  border-radius: 0 !important;
}
.btn-primary {
  background-color: #00a79d !important;
  color: #ffffff !important;
}
.btn-outline-primary {
  border: 1px solid #00a79d !important;
  color: #00a79d !important;
}
.btn-outline-secondary {
  border: 1px solid #000000 !important;
  color: #000000 !important;
}
.icon-with-btn {
  display: flex !important;
  align-items: center;
  gap: 6px;
  svg {
    height: 16px;
    width: 16px;
  }
}
.btn-danger {
  background-color: rgb(239, 83, 80) !important;
  color: #ffffff !important;
}
.btn-sm {
  padding: 7px 15px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.my-16 {
  margin: 16px 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.account-deleted {
  background-color: #ffecec; 
  color: #f44336;            
  text-align: center;
  font-weight: 600;
  padding: 10px 20px;
  width: 300px;
  margin: 0 auto;
  cursor:inherit;

}